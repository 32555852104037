@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");

body {
  background-color: #0a192f;
  color: #60f5d2;
  font-family: "Roboto Mono", monospace;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #081425;
}

::-webkit-scrollbar-thumb {
  background: #9ba4c0;
  border-radius: 10px;
}
