.about {
  text-align: center;
  background-color: #081425;
  border-radius: 15px;
  margin-bottom: 45px;
}

.about h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 40px;
}

.about h2 {
  color: #9ba4c0;
  font-weight: bolder;
  font-size: 25px;
  width: 70%;
  margin: auto;
}

.about p {
  color: #8892b0;
  font-size: 20px;
  width: 70%;
  margin: auto;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

.techTable {
  background-color: #ffffff00;
  color: #60f5d2;
  font-size: 20px;
  width: 30%;
  margin: auto;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}
