.navbar {
  background-color: #081425;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: #98a4c1 !important;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.navbar-brand:hover,
.navbar-nav:hover .nav-link:hover {
  color: #60f5d2 !important;
  transform: scale(-3px);
}

.navbar-toggler {
  border-color: #081425 !important;
}
