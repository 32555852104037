@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap);
.home {
  text-align: center;
}

.home h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 80px;
}

.home h2 {
  color: #8892b0;
  font-weight: bolder;
  font-size: 20px;
}

.home svg {
  width: 20px;
  height: 20px;
}

.linkBtn {
  color: #60f5d2;
  border-color: #60f5d2;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.linkBtn:hover {
  background-color: #0a192f;
  border-color: #60f5d2;
  color: #274979;
  transform: translateY(-3px);
}

.about {
  text-align: center;
  background-color: #081425;
  border-radius: 15px;
  margin-bottom: 45px;
}

.about h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 40px;
}

.about h2 {
  color: #9ba4c0;
  font-weight: bolder;
  font-size: 25px;
  width: 70%;
  margin: auto;
}

.about p {
  color: #8892b0;
  font-size: 20px;
  width: 70%;
  margin: auto;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

.techTable {
  background-color: #ffffff00;
  color: #60f5d2;
  font-size: 20px;
  width: 30%;
  margin: auto;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

.projects {
  text-align: center;
  background-color: #081425;
  border-radius: 15px;
  margin-bottom: 45px;
}

.projects h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 40px;
}

.projectCard {
  margin: 22px;
  border-radius: 8px;
  background-color: #0a192f;
}

.projectCard .card-text {
  color: #ccd6f6;
}

.projectCard .text-muted {
  color: #8892b0 !important;
}

.linkBtn {
  color: #60f5d2;
  border-color: #60f5d2;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.linkBtn:hover {
  background-color: #0a192f;
  border-color: #60f5d2;
  color: #274979;
  transform: translateY(-3px);
}

.projects svg {
  width: 20px;
  height: 20px;
}

.notFound {
  text-align: center;
}

h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 80px;
}

h2 {
  color: #8892b0;
  font-weight: bolder;
  font-size: 40px;
}

.navbar {
  background-color: #081425;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: #98a4c1 !important;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.navbar-brand:hover,
.navbar-nav:hover .nav-link:hover {
  color: #60f5d2 !important;
  transform: scale(-3px);
}

.navbar-toggler {
  border-color: #081425 !important;
}

body {
  background-color: #0a192f;
  color: #60f5d2;
  font-family: "Roboto Mono", monospace;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #081425;
}

::-webkit-scrollbar-thumb {
  background: #9ba4c0;
  border-radius: 10px;
}

