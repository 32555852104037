.home {
  text-align: center;
}

.home h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 80px;
}

.home h2 {
  color: #8892b0;
  font-weight: bolder;
  font-size: 20px;
}

.home svg {
  width: 20px;
  height: 20px;
}

.linkBtn {
  color: #60f5d2;
  border-color: #60f5d2;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.linkBtn:hover {
  background-color: #0a192f;
  border-color: #60f5d2;
  color: #274979;
  transform: translateY(-3px);
}
