.notFound {
  text-align: center;
}

h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 80px;
}

h2 {
  color: #8892b0;
  font-weight: bolder;
  font-size: 40px;
}
