.projects {
  text-align: center;
  background-color: #081425;
  border-radius: 15px;
  margin-bottom: 45px;
}

.projects h1 {
  color: #ccd6f6;
  font-weight: bolder;
  font-size: 40px;
}

.projectCard {
  margin: 22px;
  border-radius: 8px;
  background-color: #0a192f;
}

.projectCard .card-text {
  color: #ccd6f6;
}

.projectCard .text-muted {
  color: #8892b0 !important;
}

.linkBtn {
  color: #60f5d2;
  border-color: #60f5d2;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.linkBtn:hover {
  background-color: #0a192f;
  border-color: #60f5d2;
  color: #274979;
  transform: translateY(-3px);
}

.projects svg {
  width: 20px;
  height: 20px;
}
